@import './vars';


@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 1350px) {
    .rightActionBox{
        display: none;
    }
}

@media screen and (max-width: 1260px) {
    .music-menu{
        display: none;
    }
    .header__content{
        width: 90%;
        justify-content: space-between;
    }
    .header__search{
        width: 70%;
    }
    .header__logo-link{
        font-size: 35px;
    }
    .header__logo{
        margin-right: 40px;
    }
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1081px) {
    .playerTrack{
        max-width: none;
    }
    .player-box{
        display: grid;
    }
    .player-box__leftWrapper{
        grid-column-end: 3;
        grid-column-start: 1;
    }
    .player-box__leftWrapper .playerTime__box{
        width: 100%;
        margin-top: 25px;
    }
}

@media screen and (max-width: 1024px) {
    .famous__title{
        font-size: 30px;
    }
    .ya-share2__container_mobile.ya-share2__container_size_l .ya-share2__icon {
        height: 40px !important;
        width: 40px !important;
    }
    .ya-share2__container_shape_round.ya-share2__container_size_l .ya-share2__badge .ya-share2__icon:not(.ya-share2__icon_messenger-contact):not(.ya-share2__icon_more):not(.ya-share2__icon_copy) {
        background-size: 30px 30px !important;
    }
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 992px) {
}
@media screen and (max-width: 991px) {
}

@media screen and (max-width: 768px) {
    .famous__title{
        font-size: 25px;
    }
    .timeBox{
        margin-left: 10px;
    }
    .currentTime{
        margin-right: 10px;
    }
    .timeBox,.currentTime{
        font-size: 18px;
    }
    .footer__copy{
        flex-direction: column;
    }
    .copyright__box{
        margin-bottom: 10px;
    }
    .results__card{
        min-width: 260px;
        height: 60px;
    }
    .copyright__box-mail{
        font-size: 20px;
    }
    .active__genre-title, .search__heading{
        font-size: 25px;
    }
    .header__logo{
        margin-right: 25px;
    }
    .header__logo-link{
        font-size: 25px;       
    }
    .title{
        height: 60px;
        width: auto;
    }
    .results__card-artist, .results__card-space, .results__card-tracklink {
        color: var(--color-5);
        overflow: hidden;
        height: 19px;
        font-size: 16px;
    }
    .results__card .start{
        margin-right: 0;
        width: 40px;
        height: 40px;
        min-height: 40px;
        min-width: 40px;
    }
    .results__card-link::before{
        font-size: 20px;
    }
    .dur{
        font-size: 16px;
    }
    .results__card-download i{
        font-size: 25px;
    }
}

@media screen and (max-width: 641px) {
    .active__genre-title, .search__heading{
        font-size: 20px;
    }
    .search__heading{
        line-height: 2;
    }
    .header__inner{
        align-items: flex-start;
    }
    .header__content{
        align-items: flex-start;
        flex-direction: column;
    }
    .header__logo{
        margin-bottom: 10px;
    }
    .header__search {
        min-width: 280px;
        width: 90%;
    }
    .sidebar{
        width: 290px;
        padding: 15px;
    }
    .sidebar__list{
        margin-top: 60px;
    }
    .list-unstyled__link{
        padding: 5px 0;
    }
    .sidebar ul li a{
        font-size: 16px;
    }
    .active__genre-item,.search__track{
        margin-right: 0;
        width: 100%;
        text-align: center;
    }
    .search__track span{
        display: block;
        overflow: hidden;
    }
}

@media screen and (max-width: 641px) {
    .vk__input{
        flex-direction: column;
    }
    .vk__btn{
        width: 100%;
        margin-bottom: 15px;
    }
    .vk__link{
        padding: 15px 0 15px 15px;
        width: 100%;
    }
    .copyright__box-mail{
        flex-direction: column;
    }
    .copyright__box-logo{
        margin: 10px 0;
    }
}    

@media screen and (max-width: 481px) {
    .results{
        padding: 0 15px;
    }
    .header__inner{
        padding: 15px;
    }
    .player-box__leftWrapper{
        width: 100%;
        padding-right: 0;
    }
    .player-box{
        flex-direction: column;
        display: flex;
    }
    .player-box__central{
        margin-bottom: 20px;
        margin-right: 0;
    }
    .player-box{
        padding: 0 15px;
    }
    .playerTrack{
        text-align: center;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .playerTrack--icon{
        display: none;
    }
    .player-box__track-timebox{
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 426px) {
    .search, .active__genre{
        display: none;
    }
    .footer__copy{
        margin-bottom: 100px;
    }
    #content{
        background-color: #fff;
    }
}